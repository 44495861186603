<template>
  <div>
    <profile-header text="Generate Invite Code" />
    <v-row>
      <v-col cols="7" class="fields">
        <div class="form-error">{{ notification }}</div>
        <v-text-field
          v-model="inviteCode"
          outlined
          dense
          label="Invite code"
          :rules="inviteCodeRules"
        ></v-text-field>
        <v-btn
          outlined
          width="200px"
          :disabled="inviteCode.length < 1"
          @click="generate"
        >
          Generate
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="inviteDialog"
      persistent
      max-width="600"
    >
      <v-card class="pa-8">
        <v-card-title class="mb-5">
          <span class="text-title-dialog">Copy Link</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" icon dark @click="inviteDialog = false">
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
          <div class="my-8">
            {{ inviteLink }}
          </div>
          <v-btn
            @click="copyLink"
            outlined
            large
          >
            Copy Link
          </v-btn>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader.vue'
import awsAuth from "@/cognito/aws-auth";
export default {
  components: { ProfileHeader },
  data() {
    return {
      inviteCode: '',
      notification: '',
      inviteCodeRules: [
        (value) => !!value || "Required",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      inviteLink: '',
      inviteDialog: false,
    };
  },

  methods: {
    generate() {
      if (!this.inviteCode || this.inviteCode.length < 3) {
        // this.notification = 'Check your invite code';
        return;
      }
      awsAuth.generateInvite(this.inviteCode).then(res => {
        this.inviteLink = `${window.location.protocol}//${window.location.host}/auth?state=signup&from=web&code=${this.inviteCode}&token=${decodeURIComponent(res.data)}`;
        this.inviteDialog = true;
      }).catch(err => {
        console.log('generate err - ' + JSON.stringify(err));
      });
    },
    copyLink() {
      navigator.clipboard.writeText(this.inviteLink).then(function() {
        console.log('Invite Link Copied To Clipboard');
      }, function(err) {
        console.error('Could not copy: ', err);
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.form-error {
  color: #FF3C11!important;
  text-transform: none;
  font-weight: 450;
}
.text-title-dialog {
  font-size: 22px;
  font-weight: 600;
  line-height: 100%;
}
</style>